export const formatRowValue = (row, numberOfChart) => {
    if (row !== null && row !== undefined && row.length > 0) {
        return row.length > numberOfChart ? `${row.substring(0, numberOfChart).toLowerCase()} ...` : row.toLowerCase();
    }

    return null;
};

export const formatRowValuePlainText = (row, numberOfChart) => {
    if (row !== null && row !== undefined && row.length > 0) {
        return row.length > numberOfChart ? `${row.substring(0, numberOfChart)} ...` : row;
    }

    return null;
};

export const formatToLowerCase = (row) => {
    if (row !== null && row !== undefined && row.length > 0) {
        return row.toLowerCase();
    }
};

export const formatToUpperCase = (value) => {
    return value ? value
        // Split camel case into words
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        // Capitalize the first letter of each word
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ") : "";
};

export const formatToCapitalize = (str) => {
    if (!str || typeof str !== "string") return str;
    return str.toLowerCase().replace(/(^|\s)\S/g, (char) => char.toUpperCase());
};

export function autoBreak(label) {
    const maxLength = 16;
    const lines = [];

    for (let word of label.split(" ")) {
        if (lines.length == 0) {
            lines.push(word);
        } else {
            const i = lines.length - 1;
            const line = lines[i];

            if (line.length + 1 + word.length <= maxLength) {
                lines[i] = `${line} ${word}`;
            } else {
                lines.push(word);
            }
        }
    }

    return lines;
}

export const formatAccountType = (accountType) => {
    return {label: formatToCapitalize(accountType), value: accountType};
};

export const extractText = (label) => {
    const text = label.substring(2);
    return text;
};

export function formatValueByRemovingUnderScore(input) {
    // Check if input is null or undefined, return empty string
    if (input === null || input === undefined) {
        return "";
    }
    // Use regular expression to replace underscores with spaces
    // and convert the string to lowercase
    return input.replace(/_/g, " ").toLowerCase();
}

export const extractBaseUrl = (url) => {
    try {
        const urlObj = new URL(url);
        let hostname = urlObj.hostname;
        if (hostname.startsWith("www.")) {
            hostname = hostname.substring(4);
        }
        return hostname;
    } catch (e) {
        console.error("Invalid URL", e);
        return null;
    }
};

export function transformUrl(originalUrl) {
    // Extract the necessary parts from the original URL
    const urlPattern = /https:\/\/www\.tripadvisor\.com\/ShowUserReviews-(g\d+)-d(\d+)-r(\d+)-([\w_]+)-([\w_]+)\.html/;
    const match = originalUrl.match(urlPattern);

    if (!match) {
        throw new Error("Invalid URL format");
    }

    const [, , , reviewId, locationId] = match;

    // Construct the new URL
    const newUrl = `https://Tripadvisor.com/${locationId}/reviews${reviewId}`;

    return newUrl;
}


function decodeJWT(token) {
    if (token) {
        const getToken = token.split(".");

        return JSON.parse(window.atob(getToken[1]));
    }
}


// Function to check if the user has the ROLE_ADMIN role
export const hasAdminRole = (refreshToken) => {
    try {
        const decodedToken = decodeJWT(refreshToken);
        const roles = decodedToken?.roles || [];
        return roles.includes("ROLE_ADMIN");
    } catch (error) {
        console.error("Error decoding token:", error);
        return false;
    }
};

export const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;

    return (
        <span>
            {text.slice(0, maxLength)}
            <span>...</span>
            <span style={{color: '#905bc2', cursor: 'pointer'}}> &nbsp;Load more</span>
        </span>
    );
};

export const truncateTextInProfile = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
};

export const cleanData = (data) => {
    return decodeURIComponent(data);
};